import { lazy, Suspense, useEffect, useState } from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import {
  DataStoreContext,
  DeviceWidthContext,
} from "./shared/functions/Context";
import PrivateRoute from "./shared/auth_guards/PrivateRoute";
// import SignIn from "./admin_side/sign-in/SignIn";
import Loading from "./shared/components/Loading";
import Login from "./logged_out/login/Login";
import DataStore from "./logged_in/shared/stores/DataStore/DataStore";
// import Modal from "./shared/components/Modal";

const LoggedIn = lazy(() => import("./logged_in/Main"));
// const LoggedOut = lazy(() => import("./logged_out/Main"));
// const LoginComponent = lazy(() => import("./admin_side/sign-in/Login"));

function App() {
  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  return (
    <div>
      <DataStoreContext.Provider value={new DataStore()}>
        <DeviceWidthContext.Provider value={width}>
          <Router>
            <Switch>
              <PrivateRoute path="/user">
                <Suspense fallback={<Loading />}>
                  <LoggedIn />
                </Suspense>
              </PrivateRoute>

              <Route exact path="/login">
                <Suspense fallback={<Loading />}>
                  <Login />
                </Suspense>
              </Route>
              <Route exact path="/">
                <Suspense fallback={<Loading />}>
                  {/* <LoggedOut /> */}
                  <Login />
                </Suspense>
              </Route>
              <Route exact path="**">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Router>
        </DeviceWidthContext.Provider>
      </DataStoreContext.Provider>
    </div>
  );
}

export default App;
