import { makeAutoObservable, runInAction } from "mobx";
import { Collections } from "../../../../shared/api/Data";
import { db } from "../../../../shared/api/Firebase";
import { IMeasureObject } from "./MeasureStore";
import Perpectives from "./Perspectives";
import { IScorecardObject } from "./ScorecardStore";

/**
 * Create objectives measures/KPIs
 * Get objectives measures/KPIs
 * Update objectives measures/KPIs
 * Delete objectives measures/KPIs
 * */

type ObjectiveStatus =
  | "Completed"
  | "In-Progress On-Track"
  | "In-Progress At-Risk"
  | "Not Started";
interface IObjective {
  id: string;
  uid: string;
  departmentId: string;
  scorecardId: string;
  status: ObjectiveStatus;
  perspective: Perpectives;
  name: string;
  description: string;
}

class ObjectivesStore {
  objetives: Objective[] = [];
  isLoading = false;
  scorecard: IScorecardObject;

  constructor(store: IScorecardObject) {
    makeAutoObservable(this);
    this.scorecard = store;
    this.loadAllObjectives();
  }

  loadAllObjectives() {
    this.isLoading = true;
    const $db = db
      .collection(Collections.OBJECTIVES_COLLECTION)
      .where("scorecardId", "==", this.scorecard.asJson.id)
      .orderBy("name");
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        docs.forEach((doc: any) => this.updateObjectiveFromServer(doc));
        this.isLoading = false;
      });
    });
  }

  createObjective(data: IObjective) {
    // const objective = new Objective(this, data);
    const newData = data;
    if (this.scorecard) {
      const $doc = db.collection(Collections.OBJECTIVES_COLLECTION);
      newData.scorecardId = this.scorecard.asJson.id;
      newData.uid = this.scorecard.asJson.uid;
      newData.departmentId = this.scorecard.asJson.departmentId;
      newData.id = $doc.id;
      $doc.doc().set(newData, { merge: true });
      alert("Objective created successfully.");
    } else {
      alert("Cannot create an objective without , Go to My Scorecard!");
    }
    // return objective;
  }

  createObjectiveFromObj(data: Objective) {
    const newData = data.objective;
    if (this.scorecard) {
      const $doc = db.collection(Collections.OBJECTIVES_COLLECTION).doc();
      newData.scorecardId = this.scorecard.asJson.id;
      newData.uid = this.scorecard.asJson.uid;
      newData.departmentId = this.scorecard.asJson.departmentId || "";
      newData.id = $doc.id;

      $doc.set(newData, { merge: true });
      alert("Objective created successfully.");
    } else {
      alert("Cannot create an objective without , Go to My Scorecard!");
    }
  }

  updateObjectiveFromServer(objectiveJson: any) {
    let objective = this.objetives.find(
      (o) => o.asJson.id === objectiveJson.id
    );
    if (!objective) {
      objective = new Objective(this, objectiveJson);
      this.objetives.push(objective);
    } else {
      objective.updateFromJson(objectiveJson);
    }
  }

  updateObjective(objective: Objective) {
    const data = objective.asJson;
    db.collection(Collections.OBJECTIVES_COLLECTION)
      .doc(data.id)
      .set(data, { merge: true }); // Update Objective in firebase
  }

  // Remember to delete measures under that objective, and tasks under the measure.
  delete(objective: Objective) {
    const id = objective.asJson.id;
    db.collection(Collections.OBJECTIVES_COLLECTION).doc(id).delete(); // Delete from firebase
    this.objetives.splice(this.objetives.indexOf(objective), 1); // Remove from memory
  }

  selectObjective(objective: Objective) {
    this.scorecard.scorecardStore.selectObjective(objective);
  }

  clearSelectedObjective() {
    this.scorecard.scorecardStore.clearSelectedObjective();
  }

  createMeasure(measure: IMeasureObject) {
    const doc = db.collection(Collections.MEASURES_COLLECTION).doc();
    doc
      .set({ ...measure, id: doc.id }, { merge: true })
      .then(() => {})
      .catch(() => {
        alert("Error creating a measure");
      });
  }

  get getObjectives() {
    return this.objetives;
  }

  get getTotalNoOfObjectives() {
    return this.getObjectives.length;
  }
}

export class Objective {
  objective: IObjective;
  store: ObjectivesStore;

  constructor(store: ObjectivesStore, objective: IObjective) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.objective = objective;
  }

  save() {
    this.store.updateObjective(this);
  }

  create() {
    this.store.createObjectiveFromObj(this);
  }

  delete() {
    // First Delete from firebase
    this.store.delete(this); // Then Remove from memory
  }

  updateFromJson(objective: any) {
    this.objective = objective;
  }

  select() {
    this.store.selectObjective(this);
  }

  createMeasure(measure: IMeasureObject) {
    const newMeasure = measure;
    newMeasure.measure.uid = this.asJson.uid;
    newMeasure.measure.departmentId = this.asJson.departmentId;
    newMeasure.measure.scorecardId = this.asJson.scorecardId;
    newMeasure.measure.objectiveId = this.asJson.scorecardId;
    this.store.createMeasure(newMeasure);
  }

  get asJson() {
    return this.objective;
  }
}

type IObjectivesStore = ObjectivesStore;
type IObjectiveObject = Objective;

export type { IObjective, ObjectiveStatus, IObjectivesStore, IObjectiveObject };

export default ObjectivesStore;
