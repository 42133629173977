import { Route, Redirect } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import Loading from "../components/Loading";
import { auth } from "../api/Firebase";

const PrivateRoute = (props: any) => {
  // auth.signOut();
  const [user, loading, error] = useAuthState(auth);

  const { children, ...rest } = props;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : loading ? (
          <Loading />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
