import { makeAutoObservable, runInAction } from "mobx";
import { Collections } from "../../../../shared/api/Data";
import { db } from "../../../../shared/api/Firebase";
import { IScorecardObject } from "./ScorecardStore";
import { ITask } from "./TaskStore";

/**
 * Load measures
 * Update measures
 * Remove measures
 * Create measures
 */
type MeasureStatus = "Upward" | "Steady" | "Downward";
type MeasureType = "Increasing" | "Decreasing";
type MeasureDataType = "Currency" | "Number" | "Percentage";
interface IMeasure {
  id: string;
  uid: string;
  departmentId: string;
  scorecardId: string;
  objectiveId: string;
  status: MeasureStatus;
  name: string;
  description: string;
  maximum: number;
  minimum: number;
  baseline: number;
  target: number;
  actual: number;
  weight: number;
  createdAt: any;
  lastUpdatedOn: any;
  rating: number;
  measureType: MeasureType;
  measureDataType: MeasureDataType;
}
class MeasuresStore {
  measures: Measure[] = [];
  isLoading = false;
  scorecard: IScorecardObject;

  constructor(store: IScorecardObject) {
    makeAutoObservable(this);
    this.scorecard = store;
    this.loadMeasures();
  }

  loadMeasures() {
    this.isLoading = true;
    if (this.scorecard.asJson === null) return;
    const $db = db
      .collection(Collections.MEASURES_COLLECTION)
      .where("scorecardId", "==", this.scorecard.asJson.id);
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        docs.forEach((doc: any) => this.updateMeasureFromServer(doc));
        this.isLoading = false; // on load, set isLoadingMeasures=false
      });
    });
  }

  createMeasureFromObj(data: Measure) {
    const newData = data.measure;
    if (this.scorecard) {
      const $doc = db.collection(Collections.MEASURES_COLLECTION).doc();
      newData.scorecardId = this.scorecard.asJson.id;
      newData.uid = this.scorecard.asJson.uid;
      newData.objectiveId =
        this.scorecard.scorecardStore.selectedObjective?.asJson.id || "";
      newData.departmentId = this.scorecard.asJson.departmentId || "";
      newData.id = $doc.id;

      $doc.set(newData, { merge: true });
    } else {
      alert("Cannot create an objective without , Go to My Scorecard!");
    }
  }

  updateMeasureFromServer(measureJson: IMeasure) {
    let newMeasureJson = measureJson;
    newMeasureJson.rating =
      measureJson.measureType === "Decreasing"
        ? this.calculateDecreasingRating(newMeasureJson)
        : this.calculateIncreasingRating(newMeasureJson);
    let measure = this.measures.find(
      (m: IMeasureObject) => m.asJson.id === newMeasureJson.id
    );
    if (!measure) {
      measure = new Measure(this, newMeasureJson);
      this.measures.push(measure);
    } else {
      measure.updateFromJson(newMeasureJson);
    }
  }

  createMeasure(data: IMeasure) {
    const measure = new Measure(this, data);
    db.collection(Collections.MEASURES_COLLECTION)
      .doc()
      .set(data, { merge: true });
    return measure;
  }

  updateMeasure(measure: Measure) {
    const data = measure.asJson;
    db.collection(Collections.MEASURES_COLLECTION)
      .doc(data.id)
      .set(data, { merge: true }); // Update Measure in firebase
  }

  delete(measure: Measure) {
    const id = measure.asJson.id;
    db.collection(Collections.MEASURES_COLLECTION).doc(id).delete(); // Delete from firebase
    this.measures.splice(this.measures.indexOf(measure), 1); // Remove from memory
  }

  selectMeasure(measure: Measure) {
    this.scorecard.scorecardStore.selectMeasure(measure);
  }

  clearSelectedMeasure() {
    this.scorecard.scorecardStore.clearSelectedMeasure();
  }

  createTask(task: ITask) {
    const doc = db.collection(Collections.TASKS_COLLECTION).doc();
    doc
      .set({ ...task, id: doc.id }, { merge: true })
      .then(() => {
        // alert("Created a task");
      })
      .catch(() => {
        alert("Error creating a task");
      });
  }

  get getMeasures() {
    return this.measures;
  }

  get getTotalNoOfMeasures() {
    return this.getMeasures.length;
  }

  private calculateIncreasingRating(measure: IMeasure): number {
    const { actual, maximum, minimum, target } = measure;
    // Is it an increasing measure?
    // Default is an increasing measure.
    // Whenever the actual > max, rating is 5,
    if (actual > maximum) {
      return 5;
    }
    // Whenever the actual < min, rating is 0,
    if (actual < minimum) {
      return 0;
    }

    // Whenever minimum !==0, reset scale.
    if (minimum >= 0) {
      const newActual = actual - minimum;
      // const newMaximum = maximum - minimum;
      // const newMinimum = minimum - minimum;
      // const newBaseline = baseline - minimum;
      const newTarget = target - minimum;

      const rating = (newActual / newTarget) * 5;
      const clampedValue = this.ratingClamp(
        parseFloat((Math.round(rating * 10) / 10).toFixed(1))
      );
      return clampedValue;
    } else if (minimum < 0) {
      const newActual = actual + minimum;
      // const newMaximum = maximum + minimum;
      // const newMinimum = minimum + minimum;
      // const newBaseline = baseline + minimum;
      const newTarget = target + minimum;

      const rating = (newActual / newTarget) * 5;

      const clampedValue = this.ratingClamp(
        parseFloat((Math.round(rating * 10) / 10).toFixed(1))
      );
      return clampedValue;
    }
    return 0;
  }

  private calculateDecreasingRating(measure: IMeasure) {
    const { actual, maximum, minimum, target } = measure;
    // Is it an increasing measure?
    // Default is an increasing measure.
    // Whenever the actual < max, rating is 5,
    if (actual < minimum) {
      return 5;
    }
    // Whenever the actual > min, rating is 0,
    if (actual > maximum) {
      return 0;
    }

    const rating = (target / actual) * 5;

    const clampedValue = this.ratingClamp(
      parseFloat((Math.round(rating * 10) / 10).toFixed(1))
    );
    return clampedValue;
  }

  private ratingClamp(rating: number) {
    return Math.min(5, Math.max(0, rating));
  }
}

/**
 * Create measures tasks
 * Get measures tasks
 * Update measures tasks
 * Delete measures tasks
 */
export class Measure {
  measure: IMeasure;
  store: MeasuresStore;

  constructor(store: MeasuresStore, measure: IMeasure) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.measure = measure;
  }

  save() {
    this.store.updateMeasure(this);
  }

  updateFromJson(measure: any) {
    this.measure = measure;
  }

  create() {
    this.store.createMeasureFromObj(this);
  }

  delete() {
    // First Delete from firebase
    this.store.delete(this); // Then Remove from memory
  }

  select() {
    this.store.selectMeasure(this);
  }

  createTask(task: ITask) {
    const newTask = task;
    newTask.uid = this.asJson.uid;
    newTask.scorecardId = this.asJson.scorecardId;
    newTask.objectiveId = this.asJson.objectiveId;
    newTask.measureId = this.asJson.id;
    this.store.createTask(newTask);
  }

  get asJson() {
    return this.measure;
  }
}

type IMeasuresStore = MeasuresStore;
type IMeasureObject = Measure;

export type {
  IMeasure,
  IMeasureObject,
  MeasureStatus,
  MeasureDataType,
  MeasureType,
  IMeasuresStore,
};

export default MeasuresStore;
