import { makeAutoObservable, runInAction } from "mobx";
import { Collections } from "../../../../shared/api/Data";
import { db } from "../../../../shared/api/Firebase";
import { IScorecardObject } from "./ScorecardStore";

// Create measures tasks
// Get measures tasks
// Update measures tasks
// Delete measures tasks

type TaskStatus = "Completed" | "In-Progress" | "Archived" | "Overdue";
interface ITask {
  id: string;
  uid: string;
  scorecardId: string;
  objectiveId: string;
  measureId: string;
  status: TaskStatus;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  percentComplete: number;
  budgetSpent: number;
  totalBudget: number;
}
class TasksStore {
  tasks: Task[] = [];
  isLoading = false;
  scorecard: IScorecardObject;

  constructor(store: IScorecardObject) {
    makeAutoObservable(this);
    this.scorecard = store;
    this.loadTasks();
  }

  loadTasks() {
    this.isLoading = true;
    if (this.scorecard.asJson === null) return;
    const $db = db
      .collection(Collections.TASKS_COLLECTION)
      .where("scorecardId", "==", this.scorecard.asJson.id);
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        docs.forEach((doc: any) => this.updateTasksFromServer(doc));
        this.isLoading = false; // on load, set isLoadingMeasures=false
      });
    });
  }

  updateTasksFromServer(taskJson: any) {
    let task = this.tasks.find((m: any) => m.asJson.id === taskJson.id);
    if (!task) {
      task = new Task(this, taskJson);
      this.tasks.push(task);
    } else {
      task.updateFromJson(taskJson);
    }
  }

  createTask(data: ITask) {
    const task = new Task(this, data);
    db.collection(Collections.TASKS_COLLECTION)
      .doc()
      .set(data, { merge: true });
    return task;
  }

  updateTask(task: Task) {
    const data = task.asJson;
    db.collection(Collections.TASKS_COLLECTION)
      .doc(data.id)
      .set(data, { merge: true }); // Update task in firebase
  }

  delete(task: Task) {
    const id = task.asJson.id;
    db.collection(Collections.TASKS_COLLECTION).doc(id).delete(); // Delete from firebase
    this.tasks.splice(this.tasks.indexOf(task), 1); // Remove from memory
  }

  selectTask(task: Task) {
    this.scorecard.scorecardStore.selectTask(task);
  }

  clearSelectedTask() {
    this.scorecard.scorecardStore.clearSelectedTask();
  }

  get getTasks() {
    return this.tasks;
  }
  get getTotalNoOfTasks() {
    return this.getTasks.length;
  }
  get getNoOfTasksInProgress() {
    return this.getTasks.filter((task) => task.asJson.status === "In-Progress")
      .length;
  }
  get getNoOfTasksCompleted() {
    return this.getTasks.filter((task) => task.asJson.status === "Completed")
      .length;
  }
  get getNoOfTasksArchived() {
    return this.getTasks.filter((task) => task.asJson.status === "Archived")
      .length;
  }
}

export class Task {
  task: ITask;
  store: TasksStore;

  constructor(store: TasksStore, task: ITask) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.task = task;
  }

  save() {
    this.store.updateTask(this);
  }

  updateFromJson(task: any) {
    this.task = task;
  }

  delete() {
    // First Delete from firebase
    this.store.delete(this); // Then Remove from memory
  }

  select() {
    this.store.selectTask(this);
  }

  get asJson() {
    return this.task;
  }
}

type ITaskStore = TasksStore;
type ITaskObject = Task;

export type { ITask, ITaskObject, TaskStatus, ITaskStore };

export default TasksStore;
