import { makeAutoObservable } from "mobx";
import BatchesStore, { IBatchesStore } from "./BatchesStore";
import DepartmentsStore, { IDepartmentsStore } from "./DepartmentsStore";
import ScorecardsStore, {
  IScorecardObject,
  IScorecardStore,
} from "./ScorecardStore";
import UserStore from "./UserStore";

class DataStore {
  scorecardsStore: IScorecardStore;
  usersStore: UserStore;
  departmentsStore: IDepartmentsStore;
  batchesStore: IBatchesStore;
  currentUsercorecard: IScorecardObject | null = null;

  constructor() {
    this.usersStore = new UserStore(this);
    this.scorecardsStore = new ScorecardsStore(this);
    this.departmentsStore = new DepartmentsStore(this);
    this.batchesStore = new BatchesStore(this);

    makeAutoObservable(this, {
      scorecardsStore: false,
      usersStore: false,
      departmentsStore: false,
      batchesStore: false,
    });
  }
}

export default DataStore;
