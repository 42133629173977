import { makeAutoObservable, runInAction } from "mobx";
import { Collections } from "../../../../shared/api/Data";
import {
  auth,
  authWorker,
  db,
  functions,
} from "../../../../shared/api/Firebase";
import IUser from "../../../../shared/interfaces/User";
import DataStore from "./DataStore";

class UserStore {
  currentUser!: IUserObject;
  user: IUserObject | null = null;
  userSelectedByAdmin: IUserObject | null = null;
  allUsers: IUserObject[] = [];
  store: DataStore;
  isLoading = false;
  isLoadingUser = false;

  constructor(store: DataStore) {
    this.store = store;
    makeAutoObservable(this, {
      store: false,
    });

    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const user = await this.getCurrentUserObject(userAuth.uid);

        if (user) {
          this.store.scorecardsStore.loadActiveScorecards(user);
          this.loadAllUsers();
        }
      }
    });
  }

  // Creating a new user..
  // const { uid, email, photoURL } = userAuth;
  // await this.generateUserDocument({
  //   uid,
  //   email,
  //   displayName: "Vuli Gate",
  //   role: "Employee",
  //   departmentId: "department1",
  //   photoURL: photoURL,
  // } as IUser);

  async generateUserDocument(user: IUser) {
    if (!user) return;
    const userRef = db.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
      try {
        await userRef.set(user);
      } catch (error) {}
    }
    // return this.getUserDocument(user.uid);
  }

  async getUserDocument(uid: string) {
    if (!uid) return null;
    try {
      const userDocument = await db.doc(`users/${uid}`).get();
      const user = {
        uid,
        ...userDocument.data(),
      } as IUser;

      return user;
    } catch (error) {
      return null;
    }
  }

  async getCurrentUserObject(uid: string) {
    if (!uid) return null;
    try {
      const currentUserDoc = await this.getUserDocument(uid);
      // Create currentUser object if it's null
      if (!this.currentUser && currentUserDoc) {
        this.currentUser = new User(this, currentUserDoc);
      } else {
        // Update the currentUser
        if (this.currentUser) this.currentUser.updateFromJson(currentUserDoc);
      }
      return this.currentUser;
    } catch (error) {}
  }

  loadAllUsers() {
    this.isLoading = true;
    const $db = db
      .collection(Collections.USERS_COLLECTION)
      .orderBy("displayName");
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        docs.forEach((doc: any) => this.updateUserFromServer(doc));
        this.isLoading = false; // on load, set isLoadingMeasures=false
      });
    });
  }

  // getUser from UID
  getUserFromUid(uid: string) {
    this.isLoadingUser = true;
    let user = this.allUsers.find((m) => m.asJson.uid === uid);
    if (!user) {
      // Fetch user from database
      const $doc = db.collection(Collections.USERS_COLLECTION).doc(uid);
      $doc.onSnapshot((doc: any) => {
        const userJson: IUser = { id: doc.id, ...doc.data() };
        runInAction(() => {
          user = new User(this, userJson);
          this.user = user;
          this.allUsers.push(user);
          this.isLoadingUser = false; // on load, set isLoadingMeasures=false
        });
      });
    } else {
      this.user = user;
    }
  }

  createUserFromObj(data: User) {
    const user = data.user;
    const password = "123456";

    // // Create user firebase function..
    // const createUser = functions.httpsCallable("createUser");
    // const newUser: any = {
    //   ...user,
    //   password,
    // };
    // createUser({ ...newUser })
    //   .then((result) => {
    //     alert("Created user.");
    //     this.sendPasswordResetEmail(user.email);
    //   })
    //   .catch((error) => {
    //     alert("Could not create user.");
    //   });

    authWorker
      .createUserWithEmailAndPassword(user.email, password)
      .then((userCredential) => {
        const $user = userCredential.user;
        // Update data
        if ($user) {
          user.uid = $user.uid;
          // Update user collection
          const $doc = db
            .collection(Collections.USERS_COLLECTION)
            .doc($user.uid);
          $doc
            .set(user, { merge: true })
            .then(() => alert("Created user."))
            .catch((error) => {
              alert("Could not create user.");
            });

          authWorker.signOut();
          // Send password reset email.
          this.sendPasswordResetEmail(user.email);
        }
      })
      .catch((error) => {
        alert("Could not create user.");
      });
  }

  sendPasswordResetEmail(email: string) {
    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        // Email sent.
        alert("Password reset email sent to user");
      })
      .catch(function (error) {
        // An error happened.
        alert("Could not send Password reset email sent to user");
      });
  }

  updateUserFromServer(userJson: IUser) {
    let user = this.allUsers.find((m) => m.asJson.uid === userJson.uid);
    if (!user) {
      user = new User(this, userJson);
      this.allUsers.push(user);
      // order
      this.allUsers.sort((a, b) => {
        if (a.asJson.displayName > b.asJson.displayName) {
          return 1;
        }
        if (a.asJson.displayName < b.asJson.displayName) {
          return -1;
        }
        return 0;
      });
    } else {
      user.updateFromJson(userJson);
    }
  }

  selectUser(user: User) {
    this.userSelectedByAdmin = user;
  }

  updateUser(user: User) {
    const data = user.asJson;

    // Update collection
    db.collection(Collections.USERS_COLLECTION)
      .doc(data.uid)
      .set(data, { merge: true }); // Update Batch in firebase
  }

  delete(user: User) {
    const id = user.asJson.uid;

    // Update collection
    db.collection(Collections.USERS_COLLECTION).doc(id).delete(); // Delete from firebase
    this.allUsers.splice(this.allUsers.indexOf(user), 1); // Remove from memory
  }

  clearSelectedByAdmin() {
    this.userSelectedByAdmin = null;
  }

  get getAllUsers() {
    return this.allUsers;
  }

  get getDepartmentalUsers() {
    return this.allUsers.filter(
      (user) =>
        user.asJson.departmentId === this.currentUser?.asJson.departmentId
    );
  }
}

export class User {
  user: IUser;
  store: UserStore;

  constructor(store: UserStore, user: IUser) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.user = user;
  }

  save() {
    this.store.updateUser(this);
  }

  updateFromJson(user: any) {
    this.user = user;
  }

  create() {
    this.store.createUserFromObj(this);
  }

  delete() {
    // First Delete from firebase
    this.store.delete(this); // Then Remove from memory
  }

  select() {
    this.store.selectUser(this);
  }

  loadScorecard() {
    this.store.store.scorecardsStore.clearSelectedScorecard();
    this.store.store.scorecardsStore.getScorecardByUid(this.asJson.uid);
  }

  get asJson() {
    return this.user;
  }
}

type IUserStore = UserStore;
type IUserObject = User;

export type { IUserStore, IUserObject };

export default UserStore;
