import { makeAutoObservable, runInAction } from "mobx";
import { Collections } from "../../../../shared/api/Data";
import { db } from "../../../../shared/api/Firebase";
import AssignmentsStore, {
  IAssignmentObject,
  IAssignmentsStore,
} from "./AssignmentStore";
import { Batch } from "./BatchesStore";
import DataStore from "./DataStore";
import MeasuresStore, { IMeasureObject, IMeasuresStore } from "./MeasureStore";
import ObjectivesStore, {
  IObjectiveObject,
  IObjectivesStore,
} from "./ObjectivesStore";
import ProjectsStore, { IProjectObject, IProjectsStore } from "./ProjectsStore";
import TasksStore, { ITaskObject, ITaskStore } from "./TaskStore";
import { IUserObject } from "./UserStore";

/**
 * Create the user scorecards
 * Get user scorecards
 * Update user scorecards
 * Delete user scorecard
 */

type ScorecardStatus = "Approved" | "Rejected" | "Sent" | "Not Sent";

interface IScorecard {
  id: string;
  uid: string;
  departmentId: string;
  scorecardBatchId: string;
  status: ScorecardStatus;
  isEditable: boolean;
  name: string;
  description: string;
  assignmentsDashboard?: {
    all: number;
    archived: number;
    inProgress: number;
    resolved: number;
  };
  tasksDashboard?: {
    all: number;
    archived: number;
    inProgress: number;
    completed: number;
  };
  measuresAndObjectivesDashboard?: {
    measures: number;
    objectives: number;
  };
  projectsDashboard?: {
    company: number;
    departmental: number;
  };
}

class ScorecardsStore {
  scorecards: Scorecard[] = [];
  store: DataStore;
  isLoading = false;
  activeBatch: Batch | null = null;
  selectedScorecard: IScorecardObject | null = null;
  selectedObjective: IObjectiveObject | null = null;
  selectedMeasure: IMeasureObject | null = null;
  selectedTask: ITaskObject | null = null;
  selectedAssignment: IAssignmentObject | null = null;
  selectedProject: IProjectObject | null = null;
  selectedUser: IUserObject | null = null;

  constructor(store: DataStore) {
    this.store = store;
    makeAutoObservable(this, {
      store: false,
    });
  }

  loadActiveScorecards(currentUser: IUserObject) {
    const activeBatch = this.store.batchesStore.activeBatch;
    if (!activeBatch) {
      // alert("No active batch");
      return;
    }

    this.isLoading = true;
    const $db = db
      .collection(Collections.SCORECARDS_COLLECTION)
      .where("uid", "==", currentUser.asJson.uid)
      .where("scorecardBatchId", "==", activeBatch!.asJson.id);
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        docs.forEach((doc: any) =>
          this.updateCurrentUserScorecardFromServer(doc)
        );
        this.isLoading = false;
      });
    });
  }

  loadActiveScorecard(currentUser: IUserObject) {
    const activeBatch = this.store.batchesStore.activeBatch;
    if (!activeBatch) return;

    this.isLoading = true;
    const $doc = db
      .collection(Collections.SCORECARDS_COLLECTION)
      .doc(`${activeBatch!.asJson.id}_${currentUser.asJson.uid}`);
    $doc.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        const doc: any = { id: docSnapshot.id, ...docSnapshot.data() };
        // console.log("Loaded active scorecard: ", doc);

        runInAction(() => {
          this.updateCurrentUserScorecardFromServer(doc);
          this.isLoading = false;
        });
      } else {
        // doc.data() will be undefined in this case
        // console.log("No such document!");
      }
    });
  }

  updateCurrentUserScorecardFromServer(scorecardJson: IScorecard) {
    if (!this.store.currentUsercorecard) {
      this.store.currentUsercorecard = new Scorecard(this, scorecardJson);
    } else {
      this.store.currentUsercorecard.updateFromJson(scorecardJson);
    }
  }

  loadAllDepartmentalScorecards(departmentId: string) {
    const activeBatch = this.store.batchesStore.activeBatch;
    if (!activeBatch) {
      // alert("No active batch");
      return;
    }
    this.isLoading = true;
    const $db = db
      .collection(Collections.SCORECARDS_COLLECTION)
      .where("departmentId", "==", departmentId)
      .where("scorecardBatchId", "==", activeBatch!.asJson.id);
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        docs.forEach((doc: any) => this.updateScorecardFromServer(doc));

        this.isLoading = false;
      });
    });
  }

  loadAllScorecards() {
    const activeBatch = this.store.batchesStore.activeBatch;
    if (!activeBatch) {
      // alert("No active batch");
      return;
    }
    this.isLoading = true;
    const $db = db
      .collection(Collections.SCORECARDS_COLLECTION)
      .where("scorecardBatchId", "==", activeBatch!.asJson.id)
      .orderBy("name");
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        docs.forEach((doc: any) => this.updateScorecardFromServer(doc));
        this.isLoading = false;
      });
    });
  }

  getScorecardByUid(uid: string) {
    const activeBatch = this.store.batchesStore.activeBatch;
    if (!activeBatch) {
      // alert("No active batch");
      return;
    }

    this.isLoading = true;
    let scorecard = this.scorecards.find((m) => m.asJson.uid === uid);

    if (!scorecard) {
      // Fetch user from database
      const $doc = db
        .collection(Collections.SCORECARDS_COLLECTION)
        .doc(`${activeBatch!.asJson.id}_${uid}`);

      $doc.get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          const scorecardJson: any = {
            id: docSnapshot.id,
            ...docSnapshot.data(),
          };
          runInAction(() => {
            scorecard = new Scorecard(this, scorecardJson);
            this.selectedScorecard = scorecard;
            this.scorecards.push(scorecard);

            this.isLoading = false;
          });
        }
      });
    } else {
      this.selectedScorecard = scorecard;
    }
  }

  updateScorecardFromServer(scorecardJson: IScorecard) {
    let scorecard = this.scorecards.find(
      (s) => s.asJson.id === scorecardJson.id
    );
    if (!scorecard) {
      scorecard = new Scorecard(this, scorecardJson);
      this.scorecards.push(scorecard);
    } else {
      scorecard.updateFromJson(scorecardJson);
    }
  }

  createScorecard(data: IScorecard) {
    const scorecard = new Scorecard(this, data);
    if (!(data.scorecardBatchId && data.uid)) {
      alert(
        "Could not create scorecard, try refreshing the page, or contacting admin."
      );
      return;
    }
    const id = `${data.scorecardBatchId}_${data.uid}`;
    // Update firebase
    const doc = db.collection(Collections.SCORECARDS_COLLECTION).doc(id);
    doc
      .set({ ...data, id: id }, { merge: true })
      .then(() => {})
      .catch(() => {
        alert("Error adding scorecard");
      });
    return scorecard;
  }

  createScorecardFromObj(data: Scorecard) {
    const activeBatch = this.store.batchesStore.activeBatch;
    if (!activeBatch) {
      alert("No active batch");
      return;
    }
    if (!data.asJson.uid) {
      alert(
        "Could not create scorecard, try refreshing the page, or contacting admin."
      );
      return;
    }
    const id = `${activeBatch.asJson.id}_${data.asJson.uid}`;
    const $doc = db.collection(Collections.SCORECARDS_COLLECTION).doc(id);
    const newData = data.scorecard;
    newData.id = id;
    newData.scorecardBatchId = activeBatch.asJson.id;
    $doc.set(newData, { merge: true });
  }

  updateScorecard(scorecard: Scorecard) {
    if (scorecard.asJson.id) {
      const doc = db
        .collection(Collections.SCORECARDS_COLLECTION)
        .doc(scorecard.asJson.id);
      doc
        .set(scorecard.asJson, { merge: true })
        .then(() => {})
        .catch(() => {
          alert("Error updating scorecard");
        });
      return scorecard;
    }
  }

  delete(scorecard: Scorecard) {
    db.collection(Collections.SCORECARDS_COLLECTION)
      .doc(scorecard.asJson.id)
      .delete()
      .then(() => {})
      .catch(() => {
        alert("Error deleting scorecard");
      });
  }

  clearSelectedScorecard() {
    this.selectedScorecard = null;
  }

  sendScorecard(scorecard: Scorecard) {
    this.updateScorecard(scorecard);
  }

  changeScorecard(scorecard: Scorecard) {
    this.updateScorecard(scorecard);
  }

  approveScorecard(scorecard: Scorecard) {
    this.updateScorecard(scorecard);
  }

  rejectScorecard(scorecard: Scorecard) {
    this.updateScorecard(scorecard);
  }

  enableScorecardEditing(scorecard: Scorecard) {
    this.updateScorecard(scorecard);
  }

  disableScorecardEditing(scorecard: Scorecard) {
    this.updateScorecard(scorecard);
  }

  selectScorecard(scorecard: Scorecard) {
    this.store.currentUsercorecard = scorecard;
  }

  selectObjective(objective: IObjectiveObject) {
    this.selectedObjective = objective;
  }

  clearSelectedObjective() {
    this.selectedObjective = null;
  }

  selectMeasure(measure: IMeasureObject) {
    this.selectedMeasure = measure;
  }

  clearSelectedMeasure() {
    this.selectedMeasure = null;
  }

  selectTask(task: ITaskObject) {
    this.selectedTask = task;
  }

  clearSelectedTask() {
    this.selectedTask = null;
  }

  selectAssignment(assignment: IAssignmentObject) {
    this.selectedAssignment = assignment;
  }

  clearSelectedAssignment() {
    this.selectedAssignment = null;
  }

  selectProject(assignment: IProjectObject) {
    this.selectedProject = assignment;
  }

  clearSelectedProject() {
    this.selectedProject = null;
  }
}

/**
 * Create scorecard objectives
 * Get scorecard objectives
 * Update scorecard objectives
 * Delete scorecard objective
 */
export class Scorecard {
  scorecard: IScorecard;
  scorecardStore: ScorecardsStore;
  objectivesStore: IObjectivesStore;
  measuresStore: IMeasuresStore;
  tasksStore: ITaskStore;
  projectsStore: IProjectsStore;
  assignmentsStore: IAssignmentsStore;

  constructor(store: IScorecardStore, scorecard: IScorecard) {
    makeAutoObservable(this);
    this.scorecardStore = store;
    this.scorecard = scorecard;
    this.objectivesStore = new ObjectivesStore(this);
    this.measuresStore = new MeasuresStore(this);
    this.tasksStore = new TasksStore(this);
    this.assignmentsStore = new AssignmentsStore(this);
    this.projectsStore = new ProjectsStore(this);
  }

  save() {
    this.scorecardStore.updateScorecard(this);
  }

  create() {
    this.scorecardStore.createScorecardFromObj(this);
  }

  delete() {
    this.scorecardStore.delete(this);
  }

  select() {
    this.scorecardStore.selectScorecard(this);
  }

  updateFromJson(scorecard: IScorecard) {
    this.scorecard = scorecard;
    this.scorecardStore.updateScorecard(this);
  }

  sendScorecard() {
    this.scorecard.status = "Sent";
    this.save();
  }

  changeScorecard() {
    this.scorecard.status = "Not Sent";
    this.save();
  }

  approveScorecard() {
    this.scorecard.status = "Approved";
    this.save();
  }

  rejectScorecard() {
    this.scorecard.status = "Rejected";
    this.save();
  }

  enableScorecardEditing() {
    this.scorecard.isEditable = true;
    this.save();
  }

  disableScorecardEditing() {
    this.scorecard.isEditable = false;
    this.save();
  }

  get asJson() {
    return this.scorecard;
  }
}

type IScorecardStore = ScorecardsStore;
type IScorecardObject = Scorecard;

export type { IScorecardStore, IScorecardObject, IScorecard, ScorecardStatus };

export default ScorecardsStore;
