import { makeAutoObservable, runInAction } from "mobx";
import { Collections } from "../../../../shared/api/Data";
import { db } from "../../../../shared/api/Firebase";
import { IScorecardObject } from "./ScorecardStore";

type ProjectStatus = "Completed" | "In-Progress" | "Archived" | "Overdue";

interface IProject {
  id: string;
  scorecardBatchId: string;
  departmentId: string;
  participants: string[];
  projectLead: string;
  status: ProjectStatus;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  percentComplete: number;
  budgetSpent: number;
  totalBudget: number;
}
class ProjectsStore {
  projects: Project[] = [];
  isLoading = false;
  scorecard: IScorecardObject;

  constructor(store: IScorecardObject) {
    makeAutoObservable(this);
    this.scorecard = store;
    this.loadProjects();
  }

  loadProjects() {
    this.isLoading = true;
    if (this.scorecard.asJson === null) return;
    const $db = db
      .collection(Collections.PROJECTS_COLLECTION)
      .where("scorecardBatchId", "==", this.scorecard.asJson.scorecardBatchId);
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        docs.forEach((doc: any) => this.updateProjectFromServer(doc));
        this.isLoading = false; // on load, set isLoadingMeasures=false
      });
    });
  }

  createProjectFromObj(data: Project) {
    const newData = data.project;
    if (this.scorecard) {
      const $doc = db.collection(Collections.PROJECTS_COLLECTION).doc();
      newData.scorecardBatchId = this.scorecard.asJson.scorecardBatchId;
      newData.departmentId = this.scorecard.asJson.departmentId;
      newData.id = $doc.id;

      $doc.set(newData, { merge: true });
    } else {
      alert("Cannot create an Assignmetn without , Go to My Scorecard!");
    }
  }

  updateProjectFromServer(projectJson: any) {
    let project = this.projects.find(
      (m: any) => m.asJson.id === projectJson.id
    );
    if (!project) {
      project = new Project(this, projectJson);
      this.projects.push(project);
    } else {
      project.updateFromJson(projectJson);
    }
  }

  createProject(data: IProject) {
    const project = new Project(this, data);
    db.collection(Collections.PROJECTS_COLLECTION)
      .doc()
      .set(data, { merge: true });
    return project;
  }

  updateProject(project: Project) {
    const data = project.asJson;
    db.collection(Collections.PROJECTS_COLLECTION)
      .doc(data.id)
      .set(data, { merge: true }); // Update Project in firebase
  }

  archiveProject(project: Project) {
    const resolved = project;
    resolved.project.status = "Archived";
    this.updateProject(resolved);
  }

  delete(project: Project) {
    const id = project.asJson.id;
    db.collection(Collections.PROJECTS_COLLECTION).doc(id).delete(); // Delete from firebase
    this.projects.splice(this.projects.indexOf(project), 1); // Remove from memory
  }

  selectProject(project: Project) {
    this.scorecard.scorecardStore.selectProject(project);
  }

  clearSelectedProject() {
    this.scorecard.scorecardStore.clearSelectedProject();
  }

  get getProjects() {
    return this.projects;
  }
  get getCompanyProjectNo() {
    return this.getProjects.length;
  }
  get getDepartmentalProjectsNo() {
    return this.getProjects.filter(
      (project) => project.asJson.status === this.scorecard.asJson.departmentId
    ).length;
  }
}

/**
 *
 */
export class Project {
  project: IProject;
  store: ProjectsStore;

  constructor(store: ProjectsStore, project: IProject) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.project = project;
  }

  save() {
    this.store.updateProject(this);
  }

  archive() {
    this.store.archiveProject(this);
  }

  updateFromJson(project: any) {
    this.project = project;
  }

  create() {
    this.store.createProjectFromObj(this);
  }

  delete() {
    // First Delete from firebase
    this.store.delete(this); // Then Remove from memory
  }

  select() {
    this.store.selectProject(this);
  }

  get asJson() {
    return this.project;
  }
}

type IProjectsStore = ProjectsStore;
type IProjectObject = Project;

export type { IProject, IProjectObject, ProjectStatus, IProjectsStore };

export default ProjectsStore;
