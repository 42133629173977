import { makeAutoObservable, runInAction } from "mobx";
import { Collections } from "../../../../shared/api/Data";
import { db } from "../../../../shared/api/Firebase";
import DataStore from "./DataStore";

/**
 * Load batches
 * Update batches
 * Remove batches
 * Create batches
 */

type BatchStatus = "active" | "inactive";
interface IBatch {
  id: string;
  name: string;
  status: BatchStatus;
  isEditable: boolean;
}
class BatchesStore {
  activeBatch: Batch | null = null;
  selectedBatch: Batch | null = null;
  batches: Batch[] = [];
  isLoading = false;
  store: DataStore;

  constructor(store: DataStore) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.loadActiveBatch();
  }

  loadActiveBatch() {
    this.isLoading = true;
    const $db = db
      .collection(Collections.BATCHES_COLLECTION)
      .where("status", "==", "active")
      .orderBy("name", "desc");
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        docs.forEach((doc: IBatch) => this.updateActiveBatchFromServer(doc));
        this.isLoading = false; // on load, set isLoadingbatches=false
      });
    });
  }

  loadBatches() {
    this.isLoading = true;
    const $db = db
      .collection(Collections.BATCHES_COLLECTION)
      .orderBy("name", "desc");
    $db.onSnapshot((querySnapshot: any) => {
      const docs = querySnapshot.docs.map((doc: any) => {
        return { id: doc.id, ...doc.data() };
      });
      runInAction(() => {
        docs.forEach((doc: IBatch) => this.updateBatchFromServer(doc));
        this.isLoading = false; // on load, set isLoadingbatches=false
      });
    });
  }

  createBatchFromObj(data: Batch) {
    const newData = data.batch;
    const $doc = db.collection(Collections.BATCHES_COLLECTION).doc();
    newData.id = $doc.id;
    $doc.set(newData, { merge: true });
  }

  updateActiveBatchFromServer(batchJson: IBatch) {
    let batch = this.batches.find((m) => m.asJson.id === batchJson.id);
    if (!batch) {
      const batchObj = new Batch(this, batchJson);
      this.activeBatch = batchObj;
      this.batches.push(batchObj);
    } else {
      batch.updateFromJson(batchJson);
      this.activeBatch = batch;
    }
  }

  updateBatchFromServer(batchJson: IBatch) {
    let batch = this.batches.find((m) => m.asJson.id === batchJson.id);
    if (!batch) {
      batch = new Batch(this, batchJson);
      this.batches.push(batch);
    } else {
      batch.updateFromJson(batchJson);
    }
  }

  createBatch(data: IBatch) {
    const batch = new Batch(this, data);
    db.collection(Collections.BATCHES_COLLECTION)
      .doc()
      .set(data, { merge: true });
    return batch;
  }

  updateBatch(batch: Batch) {
    const data = batch.asJson;
    db.collection(Collections.BATCHES_COLLECTION)
      .doc(data.id)
      .set(data, { merge: true }); // Update Batch in firebase
  }

  delete(batch: Batch) {
    const id = batch.asJson.id;
    db.collection(Collections.BATCHES_COLLECTION).doc(id).delete(); // Delete from firebase
    this.batches.splice(this.batches.indexOf(batch), 1); // Remove from memory
  }

  deactivate(batch: Batch) {
    let deactivatedBatch = batch;
    deactivatedBatch.batch.status = "inactive";
    this.disableEditing(deactivatedBatch);
    // this.updateBatch(deactivatedBatch);
  }

  activate(batch: Batch) {
    let deactivatedBatch = batch;
    deactivatedBatch.batch.status = "active";
    // Disable other batches.

    // this.batches.forEach((batch) => {
    //   if (batch.asJson.status === "active") batch.deactivate();
    // });
    this.updateBatch(deactivatedBatch);
  }

  enableEditing(batch: Batch) {
    let deactivatedBatch = batch;
    deactivatedBatch.batch.isEditable = true;
    this.updateBatch(deactivatedBatch);
  }

  disableEditing(batch: Batch) {
    let deactivatedBatch = batch;
    deactivatedBatch.batch.isEditable = false;
    this.updateBatch(deactivatedBatch);
  }

  selectBatch(batch: Batch) {
    this.selectedBatch = batch;
  }

  clearSelectedBatch() {
    this.selectedBatch = null;
  }

  // activate
  //

  get getBatches() {
    return this.batches;
  }
}

/**
 * Create batches tasks
 * Get batches tasks
 * Update batches tasks
 * Delete batches tasks
 */
export class Batch {
  batch: IBatch;
  store: BatchesStore;

  constructor(store: BatchesStore, batch: IBatch) {
    makeAutoObservable(this, {
      store: false,
    });
    this.store = store;
    this.batch = batch;
  }

  save() {
    this.store.updateBatch(this);
  }

  updateFromJson(batch: IBatch) {
    this.batch = batch;
  }

  create() {
    this.store.createBatchFromObj(this);
  }

  delete() {
    this.store.delete(this);
  }

  activate() {
    this.store.activate(this);
  }

  deactivate() {
    this.store.deactivate(this);
  }

  enableEditing() {
    this.store.enableEditing(this);
  }

  disableEditing() {
    this.store.disableEditing(this);
  }

  select() {
    this.store.selectBatch(this);
  }

  get asJson() {
    return this.batch;
  }
}

type IBatchesStore = BatchesStore;
type IBatchObject = Batch;

export type { IBatch, IBatchObject, BatchStatus, IBatchesStore };

export default BatchesStore;
